<template>
  <v-container fluid class="header-section">
    <v-row class="fill-height">
      <v-col cols="12" md="6" class="text-col">
        <div class="logo-container">
          <img src="@/assets/logo.svg" alt="Beeasy Logo" class="logo">
        </div>
        <div class="text">
          <h1 class="display-2 white--text title-text">
            A ÚNICA PLATAFORMA DE <span class="highlight">FRANQUIA ONLINE</span> DO MUNDO!!
          </h1>
          <p class="subheading white--text">
            Em apenas 1 minuto, seu revendedor poderá abrir uma franquia da sua empresa, e já começar a vender seus
            produtos com uso da nossa tecnologia.
          </p>
          <p class="highlight-text gradient-text">
            Venha fazer parte da colmeia!
          </p>
        </div>
        <button class="cta-button" @click="openWhatsapp"
          target="_blank">
          Quero me tornar uma franquia online
        </button>
      </v-col>
      <v-col cols="12" md="6" class="image-col">
        <img src="@/assets/images/WEBP/Prancheta 1 cópia 3.webp" alt="Pessoa sorrindo" class="person-image">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import openWhatsapp from '@/utils/openWhatsapp';
export default {
  name: 'HeaderSection',
  methods:{
    openWhatsapp(){
      openWhatsapp();
    }
  }
};
</script>

<style scoped>
.header-section {
  background-image: url('@/assets/background/PC/WEBP/Fundo landig Page PC 1.webp');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  padding: 0 5%;
}

.text-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}

.logo-container {
  text-align: start;
  margin-bottom: 20px;
  margin-top: 10px;
}

.logo {
  max-width: 150px;
}

.title-text {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.highlight {
  color: #FFC107;
}

.subheading {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.highlight-text {
  font-weight: bold;
  margin-bottom: 20px;
}

.cta-button {
  background-color: #ffeb3b;
  border-radius: 10px;
  color: black;
  font-weight: bold;
  margin-top: 20px;
  padding: 10px 20px;
  width: 65%;
  white-space: normal;
  text-align: center;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.person-image {
  max-height: 80vh;
  object-fit: contain;
  margin-left: auto;
  border-radius: 10px;
}

/* Estilos para dispositivos móveis */
@media (max-width: 600px) {
  .header-section {
    height: auto;
    padding: 20px;
  }

  .logo-container {
    margin-top: 20px;
  }

  .logo {
    max-width: 80px;
  }

  .title-text {
    font-size: 1.5rem;
  }

  .subheading {
    font-size: 0.9rem;
  }

  .highlight-text {
    font-size: 1rem;
  }

  .cta-button {
    width: 100%;
    min-width: unset;
    margin-top: 10px;
    padding: 10px 20px;
    white-space: normal;
    font-size: 0.8rem;
    text-align: center;
  }

  .person-image {
    display: none;
  }
}
</style>
