<template>
  <v-app>
    <HeaderSection />
    <VideoSection />
    <HowItWorksSection />
    <FranchiseComparisonSection />
    <AdvantageSection />
    <FinancialControlSection />
    <TestimonialsSection />
    <ColmeiaSection />
    <FooterSection />
  </v-app>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue';
import VideoSection from '@/components/VideoSection.vue';
import HowItWorksSection from '@/components/HowItWorksSection.vue';
import FranchiseComparisonSection from '@/components/FranchiseComparisonSection.vue';
import AdvantageSection from '@/components/AdvantageSection.vue';
import FinancialControlSection from '@/components/FinancialControlSection.vue';
import TestimonialsSection from '@/components/TestimonialsSection.vue';
import ColmeiaSection from '@/components/ColmeiaSection.vue';
import FooterSection from '@/components/FooterSection.vue';

export default {
  name: 'LandingPage',
  components: {
    HeaderSection,
    VideoSection,
    HowItWorksSection,
    FranchiseComparisonSection,
    AdvantageSection,
    FinancialControlSection,
    TestimonialsSection,
    ColmeiaSection,
    FooterSection
  },
};
</script>

<style scoped>
@import '@/assets/global.css';
</style>
