<template>
  <v-container fluid class="advantage-section">
    <v-row class="fill-height">
      <v-col cols="12" md="6" class="text-col">
        <h2 class="section-title pl-8">Quais as vantagens para o seu franqueado?</h2>
        <p class="description pl-8">Quando assinar um plano, o franqueado poderá:</p>
        <ul class="advantages-list pl-8">
          <li><v-icon color="green" class="check-icon">mdi-check-circle</v-icon> Criar sua loja em apenas 1 minuto.</li>
          <li><v-icon color="green" class="check-icon">mdi-check-circle</v-icon> Definir um valor de venda com o lucro
            desejado.</li>
          <li><v-icon color="green" class="check-icon">mdi-check-circle</v-icon> Adquirir seu domínio próprio.</li>
          <li><v-icon color="green" class="check-icon">mdi-check-circle</v-icon> Utilizar ferramentas de marketing.</li>
        </ul>
        <button class="cta-button" color="yellow" @click="openWhatsapp"
          target="_blank">
          Quero me tornar uma franquia online
        </button>
      </v-col>
      <v-col cols="12" md="6" class="image-col">
        <img src="@/assets/images/PNG/Bee.png" alt="Bee Image" class="bee-image">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import openWhatsapp from '@/utils/openWhatsapp';
export default {
  name: 'AdvantageSection',
  methods:{
    openWhatsapp(){
      openWhatsapp();
    }
  }
};
</script>

<style scoped>
.advantage-section {
  background-image: url('@/assets/background/PC/PNG/Fundo-Landing-page_05.png');
  background-size: cover;
  background-position: center;
  padding: 60px 0;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  padding: 0 40px;
  word-wrap: break-word;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.description {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.advantages-list {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.advantages-list li {
  font-size: 1.2rem;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.check-icon {
  margin-right: 10px;
  font-size: 1.5rem;
}

.cta-button {
  background-color: #ffeb3b;
  border-radius: 10px;
  color: black;
  font-weight: bold;
  margin-top: 20px;
  padding: 10px 20px;
  width: 65%;
  white-space: normal;
  text-align: center;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.image-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bee-image {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

/* Estilos para dispositivos móveis */
@media (max-width: 600px) {
  .advantage-section {
    height: 60vh;
    padding: 20px;
  }

  .text-col {
    padding: 0 20px;
  }

  .section-title {
    font-size: 1.5rem;
  }

  .description {
    font-size: 1rem;
  }

  .advantages-list li {
    font-size: 0.9rem;
  }

  .cta-button {
    font-size: 0.7rem;
    min-width: 100%;
    padding: 10px;
    width: 88%;
    margin: 0 auto;
  }

  .image-col {
    display: none;
  }
}
</style>
