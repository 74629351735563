<template>
  <v-container fluid class="how-it-works-section">
    <v-row class="fill-height text-center align-center">
      <v-col cols="12">
        <v-card class="info-card">
          <v-card-text>
            <h2 class="third-section-title">Como <span class="highlight">funciona?</span></h2>
            <v-row>
              <v-col cols="12" md="3" class="text-center">
                <img src="@/assets/icons/SVG/Emblema 1.svg" alt="Emblema 1" class="emblema">
                <p class="emblema-text">Você empresário tem sua loja online onde já vende produtos e deseja alavancar
                  suas vendas</p>
              </v-col>
              <v-col cols="12" md="3" class="text-center">
                <img src="@/assets/icons/SVG/Emblema 2.svg" alt="Emblema 2" class="emblema">
                <p class="emblema-text">Nós espelhamos os produtos do seu site na plataforma Beeasy</p>
              </v-col>
              <v-col cols="12" md="3" class="text-center">
                <img src="@/assets/icons/SVG/Emblema 3.svg" alt="Emblema 3" class="emblema">
                <p class="emblema-text">As pessoas interessadas assinam um plano e em poucos passos terão uma loja e se
                  tornam franqueadas da sua loja</p>
              </v-col>
              <v-col cols="12" md="3" class="text-center">
                <img src="@/assets/icons/SVG/Emblema 4.svg" alt="Emblema 4" class="emblema">
                <p class="emblema-text">O franqueado vende e os pedidos automaticamente são enviados para sua loja, onde
                  pode manter toda sua logística de envio diretamente pela sua empresa</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HowItWorksSection',
};
</script>

<style scoped>
.how-it-works-section {
  background-image: url('@/assets/background/PC/PNG/Fundo-Landing-page_03.png');
  background-size: cover;
  background-position: center top;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.info-card {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 20px;
  border-radius: 10px;
}

.third-section-title {
  color: #FFFFFF;
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.emblema {
  max-width: 100px;
  margin-bottom: 20px;
}

.emblema-text {
  font-size: 1rem;
  color: white;
}
</style>
