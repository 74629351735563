<template>
  <v-container fluid class="financial-control-section">
    <!-- Controle Financeiro Section -->
    <v-row class="fill-height">
      <v-col cols="12" md="6" class="image-col">
        <img src="@/assets/images/WEBP/Prancheta 1 cópia 2.webp" alt="Financial Control Image" class="financial-image">
      </v-col>
      <v-col cols="12" md="6" class="details-col">
        <h2 class="section-title">Controle Financeiro</h2>
        <p class="description">
          O franqueado terá total controle financeiro sobre suas comissões de vendas, incluindo taxas, lucro líquido e
          muito mais, tudo gerenciado com nossa avançada tecnologia de inteligência artificial.
        </p>
      </v-col>
    </v-row>

    <!-- Projeção de Faturamento Section -->
    <h3 class="projection-title pl-2">Quer saber quanto você pode <strong style="color: yellow;">faturar?</strong></h3>
    <v-row class="fill-height projection-section">
      <v-col cols="12" class="text-center projection-col">
        <div class="projection-card">
          <p class="projection-header">Projeção de Faturamento Anual</p>
          <div class="projection-content">
            <!-- <div class="projection-item">
              <span class="item-label">Valor da assinatura do franqueado</span>
              <span>R$ 199,90</span>
            </div> -->
            <div class="projection-item slider-container">
              <span class="item-label">Valor da mensalidade</span>
              <v-slider v-model="price" :min="199.90" :max="599.90" :step="9.9" class="slider" thumb-label></v-slider>
              <span>{{ price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
            </div>
            <div class="projection-item slider-container">
              <span class="item-label">Assinaturas por mês</span>
              <v-slider v-model="salesPerMonth" :min="1" :max="90" :step="1" class="slider" thumb-label></v-slider>
              <span>{{ salesPerMonth }}</span>
            </div>
            <v-divider class="pb-4"></v-divider>
            <div class="projection-result">
              <span>Em 12 meses você irá faturar</span>
            </div>
            <span class="result-value">{{ projectedEarnings.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}<small style="color: #000">*</small></span>
          </div>
        </div>
        <button class="cta-button" color="yellow" @click="openWhatsapp"
          target="_blank">
          Quero me tornar uma franquia online
        </button>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import openWhatsapp from '@/utils/openWhatsapp';
export default {
  name: 'FinancialControlSection',
  data() {
    return {
      price: 72,
      salesPerMonth: 15,
      cost: 40,
    };
  },
  computed: {
    projectedEarnings() {
      return  ((this.price * this.salesPerMonth) * 12)
            + ((this.price * this.salesPerMonth) * 11) 
            + ((this.price * this.salesPerMonth) * 10) 
            + ((this.price * this.salesPerMonth) * 9) 
            + ((this.price * this.salesPerMonth) * 8) 
            + ((this.price * this.salesPerMonth) * 7) 
            + ((this.price * this.salesPerMonth) * 6) 
            + ((this.price * this.salesPerMonth) * 5)
            + ((this.price * this.salesPerMonth) * 4) 
            + ((this.price * this.salesPerMonth) * 3) 
            + ((this.price * this.salesPerMonth) * 2) 
            + ((this.price * this.salesPerMonth) * 1);
    },
  },
  methods:{
    openWhatsapp(){
      openWhatsapp();
    }
  }
};
</script>

<style scoped>
.financial-control-section {
  background-image: url('@/assets/background/PC/PNG/Fundo-Landing-page_06.png');
  background-size: cover;
  background-position: center;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.financial-image {
  max-width: 100%;
  height: auto;
}

.details-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  padding: 20px;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.description {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.projection-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #FFFFFF;
}

.projection-section {
  padding: 60px 0;
  width: 90%;
  max-width: 800px;
}

.projection-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projection-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  /* Ajustar a largura do cartão de projeção */
}

.projection-header {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.projection-content {
  display: flex;
  flex-direction: column;
}

.projection-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.slider-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.item-label {
  flex: 1;
  text-align: left;
}

.slider {
  flex: 4;
  margin: 0 10px;
}

.projection-result {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
}

.result-value {
  color: #FFC107;
  font-size: 2rem;
}

.cta-button {
  background-color: #ffeb3b;
  border-radius: 10px;
  color: black;
  font-weight: bold;
  margin-top: 20px;
  padding: 10px 20px;
  width:70%;
  white-space: normal;
  text-align: center;
  font-size: 1.5rem;
  text-transform: uppercase;
}

/* Estilos para dispositivos móveis */
@media (max-width: 600px) {
  .financial-control-section {
    height: auto;
    padding: 20px;
  }

  .section-title {
    font-size: 2rem;
  }

  .description {
    font-size: 1rem;
  }

  .projection-title {
    font-size: 1.2rem;
  }

  .projection-card {
    padding: 15px;
  }

  .cta-button {
    font-size: 0.6rem;
    padding: 10px 20px;
    width: 100%;
  }
}
</style>
