<template>
  <v-container fluid class="testimonials-section">
    <v-row class="fill-height">
      <v-col cols="12" class="text-center">
        <h2 class="section-title">O que nossos Franqueadores <strong style="color: yellow;">estão falando?</strong></h2>
      </v-col>
      <v-col cols="12" class="text-center">
        <div class="testimonials-container align-self-center">
          <v-card class="testimonial-card"><i>"A parceria com a empresa transformou nosso negócio e nos deu o suporte
              necessário para crescer."</i></v-card>
          <v-card class="testimonial-card"><i>"Ser um franqueado tem sido uma experiência incrível, com treinamento
              constante e apoio em todas as etapas."</i></v-card>
          <v-card class="testimonial-card"><i>"Nosso faturamento aumentou significativamente desde que nos tornamos
              parte da rede de franquias."</i></v-card>
          <v-card class="testimonial-card"><i>"A empresa realmente se preocupa com o sucesso de seus franqueados,
              proporcionando todas as ferramentas e recursos necessários."</i></v-card>
        </div>
      </v-col>
      <v-col cols="12" class="text-center">
        <button class="cta-button" color="yellow" @click="openWhatsapp"
          target="_blank">
          Quero me tornar uma franquia online
        </button>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import openWhatsapp from '@/utils/openWhatsapp';
export default {
  name: 'TestimonialsSection',
  methods:{
    openWhatsapp(){
      openWhatsapp();
    }
  }
};
</script>

<style scoped>
.testimonials-section {
  background-image: url('@/assets/background/PC/PNG/Fundo-Landing-page_07.png');
  background-size: cover;
  background-position: center;
  padding: 60px 20px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  margin-bottom: 40px;
}

.testimonials-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
  gap: 20px;
  flex-wrap: wrap;
}

.testimonial-card {
  background-color: white;
  width: 200px;
  height: 300px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cta-button {
  background-color: #ffeb3b;
  border-radius: 10px;
  color: black;
  font-weight: bold;
  margin-top: 20px;
  padding: 10px 20px;
  width: 40%;
  white-space: normal;
  text-align: center;
  font-size: 1.5rem;
  text-transform: uppercase;
}

/* Estilos para dispositivos móveis */
@media (max-width: 600px) {
  .testimonials-container {
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    /* Aumentei o padding aqui */
  }

  .testimonial-card {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .section-title {
    font-size: 2rem;
  }

  .cta-button {
    font-size: 0.7rem;
    width: 40vh;
    padding: 10px 20px;
  }
}
</style>
