<template>
  <v-container fluid class="video-section">
    <v-row class="fill-height">
      <v-col cols="12" class="d-flex justify-center align-center">
        <div class="video-container">
          <iframe class="video-player" src="https://www.youtube.com/embed/vq932wPMpD8?si=MHUmi6PE6by1W84y" frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
          </iframe>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'VideoSection',
};
</script>

<style scoped>
.video-section {
  background-image: url('@/assets/background/PC/PNG/Fundo-Landing-page_02.png');
  background-size: cover;
  background-position: center top;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-container {
  position: relative;
  width: 70%;
  padding-bottom: 39.37%;
  height: 0;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

@media (max-width: 600px) {

  .video-section {
    height: 60vh;
  }

  .video-container {
    width: 100%;
    padding-bottom: 56.25%;
    margin: 0 10px;
  }
}
</style>
