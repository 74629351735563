<template>
  <v-container fluid class="franchise-comparison-section">
    <v-row class="comparison-details">
      <v-col cols="12" md="6" class="text-center">
        <h2 class="comparison-title">Franquia Física</h2>
        <img src="@/assets/images/PNG/Franquia Física.png" alt="Franquia Física" class="comparison-image">
      </v-col>
      <v-col cols="12" md="6" class="text-center">
        <h2 class="comparison-title highlight">Franquia Digital</h2>
        <img src="@/assets/images/PNG/Franquia Digital.png" alt="Franquia Digital" class="comparison-image">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'FranchiseComparisonSection',
};
</script>

<style scoped>
.franchise-comparison-section {
  background-image: url('@/assets/background/PC/PNG/Fundo-Landing-page_04.png');
  background-size: cover;
  background-position: center;
  padding: 40px 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comparison-title {
  color: white;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.highlight {
  color: #FFC107;
}

.comparison-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.comparison-image {
  max-width: 100%;
  height: auto;
}

/* Estilos para dispositivos móveis */
@media (max-width: 600px) {
  .comparison-title {
    font-size: 1.5rem;
  }

  .comparison-image {
    max-width: 90%;
    margin-bottom: 20px;
  }
}
</style>
