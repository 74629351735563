<template>
  <v-container fluid class="colmeia-section">
    <v-row class="fill-height" align="center">
      <v-col cols="12" md="6" class="image-col">
        <img src="@/assets/images/WEBP/Prancheta 1.webp" alt="Bee Image" class="bee-image">
      </v-col>
      <v-col cols="12" md="6" class="text-col">
        <h2 class="section-title">Faça parte dessa Colmeia!</h2>
        <p class="description">
          Seu e-commerce não sofrerá alterações! Os franqueados terão um site criado em apenas 1 minuto, com todos os
          produtos já no sistema prontos para venda!
        </p>
        <div class="button-container">
          <button class="cta-button" color="yellow" @click="openWhatsapp"
            target="_blank">
            Quero me tornar uma franquia online
          </button>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import openWhatsapp from '@/utils/openWhatsapp';
export default {
  name: 'ColmeiaSection',
  methods:{
    openWhatsapp(){
      openWhatsapp();
    }
  }
};
</script>

<style scoped>
.colmeia-section {
  background-image: url('@/assets/background/PC/WEBP/Fundo landig Page PC 8.webp');
  background-size: cover;
  background-position: center;
  padding: 60px 20px;
  height: 100vh;
}

.image-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bee-image {
  max-width: 100%;
  height: auto;
}

.text-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
  padding: 20px;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: black;
  margin-bottom: 20px;
}

.description {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.button-container {
  display: flex;
  justify-content: center;
}

.cta-button {
  background-color: #ffeb3b;
  border-radius: 10px;
  color: black;
  font-weight: bold;
  margin-top: 20px;
  padding: 10px 20px;
  width: 65%;
  white-space: normal;
  text-align: center;
  font-size: 1.5rem;
  text-transform: uppercase;
}

/* Estilos para dispositivos móveis */
@media (max-width: 960px) {
  .colmeia-section {
    height: auto;
    padding: 20px;
  }

  .image-col {
    display: none;
  }

  .section-title {
    font-size: 2rem;
  }

  .description {
    font-size: 1rem;
  }

  .button-container {
    justify-content: center;
  }

  .cta-button {
    font-size: 0.8rem;
    width: 100%;
    padding: 10px 20px;
  }
}
</style>
