<template>
  <v-container fluid class="footer-section">
    <v-row class="fill-height" align="center" justify="center">
      <v-col cols="12" class="text-center">
        <h2 class="section-title">Alguma dúvida?</h2>
        <p class="description">Fale com nossa equipe.</p>
        <v-btn class="cta-button" color="yellow" @click="openWhatsapp"
          target="_blank">
          Entre em contato
        </v-btn>
      </v-col>
      <v-col cols="12" class="text-center">
        <img src="@/assets/logo.png" alt="Beeasy Logo" class="beeasy-logo">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import openWhatsapp from '@/utils/openWhatsapp';
export default {
  name: 'FooterSection',
  methods:{
    openWhatsapp(){
      openWhatsapp();
    }
  }
};
</script>

<style scoped>
.footer-section {
  background-image: url('@/assets/background/PC/WEBP/Fundo landig Page PC 9.webp');
  background-size: cover;
  background-position: center;
  padding: 60px 20px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
}

.description {
  font-size: 1.2rem;
  color: white;
  margin-bottom: 30px;
}

.cta-button {
  background-color: #FFC107;
  color: black;
  font-weight: bold;
  padding: 15px 30px;
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.beeasy-logo {
  max-width: 150px;
}

/* Estilos para dispositivos móveis */
@media (max-width: 600px) {
  .footer-section {
    padding: 20px;
  }

  .section-title {
    font-size: 2rem;
  }

  .description {
    font-size: 1rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .beeasy-logo {
    max-width: 100px;
  }
}
</style>
